<template>
  <div>
    <top-nav></top-nav>
    <div class="home-logo">
      <img src="../../assets/image/bank/logo.png">
    </div>

    <div class="home-nav">
      <div class="nav-item-left">
        <div class="nav-item" v-if="isBind" @click="toUrl('/bank/personal')">
          <div class="nav-item-info6">
            <div class="grade-cont">
              <img :src="getImageUrl(bank_level_img)">
            </div>
            <div class="title">
              {{ $t('个人资产') }}
            </div>

            <div class="total-assets">
              {{ $t('总资产') }}
              <img v-if="istotalAssets" @click.capture.stop="istotalAssets =!istotalAssets" class="show_icon"
                   src="../../assets/image/index/not_show_icon.png"/>
              <img v-else @click.capture.stop="istotalAssets =!istotalAssets" class="show_icon"
                   src="../../assets/image/index/show_icon.png"/>
            </div>
            <div class="total-assets-number">
              <span v-if="istotalAssets"> {{ balanceOf }}</span>
              <span v-else>***</span>
            </div>
          </div>
          <img class="nav-bg" src="../../assets/image/bank/bg6.png">
        </div>
        <div class="nav-item" v-else @click="toUrl('/bank/personal')">
          <div class="nav-item-info1">
            <div class="title">
              10BUR{{ $t('银行') }}
              <br/>
              {{ $t('开户') }}
            </div>
            <div class="open-account-but" @click.capture.stop="activateBank">
              {{ $t('去开户') }}
            </div>
          </div>
          <img class="nav-bg" src="../../assets/image/bank/bg1.png">
        </div>

        <div class="nav-item" @click="stayTuned">
          <div class="nav-item-info2">
            <div class="title">{{ $t('元交易') }}</div>
          </div>
          <img class="nav-bg" src="../../assets/image/bank/bg3.png">
        </div>

      </div>

      <div class="nav-item-right">
        <div class="nav-item" @click="toUrl('/bank/storage')">
          <div class="nav-item-info2">
            <div class="title">{{ $t('元储存') }}</div>
          </div>
          <img class="nav-bg" src="../../assets/image/bank/bg2.png">
        </div>
        <!--        <div class="nav-item" @click="toUrl('/bank/share')">-->
        <div class="nav-item" @click="toUrl('/bank/invite')">
          <div class="nav-item-info2">
            <div class="title">{{ $t('邀请') }}</div>
          </div>
          <img class="nav-bg" src="../../assets/image/bank/bg7.png">
        </div>
        <div class="nav-item" @click="stayTuned">
          <div class="nav-item-info2">
            <div class="title">{{ $t('更多期待') }}...</div>
          </div>
          <img class="nav-bg" src="../../assets/image/bank/bg5.png">
        </div>
      </div>
    </div>

    <div class="introduce-cont">
      <p class="title">{{ $t('BUINING BANK 核心优势') }}</p>
      <p style="margin-top: 1.5rem">
        {{ $t('体系优势：') }}
        <br/>
        {{ $t('全球顶级的资深银行家团队构建Al智能体系，引领全球银行进入生态4.0财富平权，用算力去战胜智力，将财富主动权交还给全球公民。') }}
      </p>
      <p style="margin-top: 1.5rem">
        {{ $t('技术优势：') }}
        <br/>
        {{ $t('自建人工智能理财模型，平均夏普辛26.7，最大回撤6.2%，能做到超高无风险收益比，为储户高息收入保驾护航。智能投保、衍生杠杆交易、最大化复合流动性。') }}
      </p>
      <p style="margin-top: 1.5rem">
        {{ $t('产品优势：') }}
        <br/>
        {{ $t('六大契约业务全在链上进行，完全去中心化。一美金就能投资，人人可参与，随进随出。小钱参与活期、大钱放进定期、高回报衍生杠杆，抢占财富先人一步。') }}
      </p>

    </div>
  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {mapState} from "vuex";
import {activateBank} from "../../api";

export default {
  name: "index",
  data() {
    return {
      istotalAssets: true,
      isBind: false,
      account: '',
      balanceOf: '-',
      bank_level_img: 'grade0.png',
    }
  },
  components: {TopNav},
  computed: {
    ...mapState({
      userInfo: state => state.app.userInfo,
    })
  },
  created() {

    // this.$store.dispatch('bur/initReward')
    // this.$store.dispatch('bur/getAlreadyRewards','0xf40ce4d32e2e50bd231dc76bcbdfc126e0a9dfbf')
    // this.$store.dispatch('bur/getUnclaimedRewards','0xf40ce4d32e2e50bd231dc76bcbdfc126e0a9dfbf')


    // this.$store.dispatch('bur/initUser')
    // this.$store.dispatch('bur/getAllowance', '0xb68469b41873c4d3ffc4b0e5b7fc3e05c19ee7c1')
    // this.$store.dispatch('bur/getBalance', '0xb68469b41873c4d3ffc4b0e5b7fc3e05c19ee7c1')
    // //
    // this.$store.dispatch('bur/initBank')
    // this.$store.dispatch('bur/getBinds', '0xb68469b41873c4d3FFC4B0E5B7Fc3e05C19ee7c1')
    // this.$store.dispatch('bur/getCurrentDeposit', '0xb68469b41873c4d3FFC4B0E5B7Fc3e05C19ee7c1')
    // this.$store.dispatch('bur/getUsrFixedHasDeposit', '0xb68469b41873c4d3FFC4B0E5B7Fc3e05C19ee7c1')


    this.getUserInfo()
    this.init()
  },
  methods: {
    async init() {
      await this.getAccount()
      await this.getUserBind()
      this.balanceOf = await this.$store.dispatch('bur/getBalanceOf', this.account)
    },
    async getAccount() {
      await this.$store.dispatch('bur/getAccount')
          .then(res => {
            if (res) {
              console.log(res);
              this.account = res
            }
          })
    },
    async getUserBind() {
      let that = this;
      await this.$store.dispatch('bur/getBinds', that.account)
          .then(res => {
            that.isBind = res
          })
    },
    getUserInfo() {
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('app/getUserInfo')
          .then((data) => {
            console.log('getUserInfo-----', data);
            if (data.code == 200) {
               this.bank_level_img = 'grade'+ data.data.bank_level +'.png'
              this.getImageUrl(this.bank_level_img)
              console.log('bank_level_img-----', this.bank_level_img)
              // if (data.data.b_pid == 0) {
              //   this.$router.push({
              //     path: '/bank/binding'
              //   })
              // }
            }
          })
          .finally(() => {
            this.$toast.clear()
          })
    },
    getImageUrl(filename) {
      return require(`../../assets/image/bank/grade/${filename}`);
    },
    activateBank() {
      this.$router.push({
        path: '/bank/binding'
      })

      // let that = this;
      // this.$toast.loading({
      //   message: '',
      //   duration: 0,
      // });
      // activateBank()
      //     .then(({data}) => {
      //       console.log(data);
      //       if (data.code == 200) {
      //         that.getUserInfo()
      //         setTimeout(() => {
      //           that.$toast(that.$t('开户成功'));
      //         }, 500)
      //       } else {
      //         setTimeout(() => {
      //           that.$toast(data.message);
      //         }, 500)
      //       }
      //     })
      //     .finally(() => {
      //       that.$toast.clear()
      //     })
    },
    toUrl(url) {
      this.$router.push({
        path: url
      })
    },
    stayTuned() {
      this.$toast(this.$t("敬请期待"));
    },
  }
}
</script>

<style scoped lang="less">
.home-logo {
  text-align: center;
  padding: 1.61rem 0;
  background: url("../../assets/image/index/index_bg.png") no-repeat;
  background-size: 100%;

  img {
    width: 8.11rem;
  }
}

.home-nav {
  padding: 0 4.5vw;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  .nav-item-left {
    position: relative;
    float: left;
    width: 44vw;
  }

  .nav-item-right {
    position: relative;
    float: right;
    width: 44vw;
  }

  .nav-item {
    position: relative;
    margin-bottom: 1.4vw;

  }

  .nav-bg {
    width: 100%;
  }

  .nav-item-info1 {
    position: absolute;
    top: 5vw;
    left: 3vw;

    .title {
      font-size: 1.43rem;
      font-weight: bold;
      font-style: italic;
      color: #13160F;
      line-height: 2.02rem;
    }

    .open-account-but {
      margin-top: 0.93rem;
      display: inline-block;
      background: #13160F;
      border-radius: 2rem;
      padding: 0.65rem 0.9rem;
      font-size: 0.79rem;
      color: #E7BD71;
    }
  }

  .nav-item-info6 {
    position: absolute;
    top: 5vw;
    left: 3vw;

    .grade-cont {
      padding-top: 0.2rem;

      img {
        height: 1.6rem;
      }

      margin-bottom: 0.8rem;
    }

    .title {
      font-size: 1.43rem;
      font-weight: bold;
      font-style: italic;
      color: #E7BD71;
    }

    .total-assets {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      font-size: 0.86rem;
      color: #6E6045;
    }

    .total-assets-number {
      font-size: 1.43rem;
      font-weight: bold;
      color: #E7BD71;
    }

    .show_icon {
      padding-left: 3vw;
      height: 0.79rem;
    }

  }

  .nav-item-info2 {
    position: absolute;
    top: 5vw;
    left: 5vw;

    .title {
      font-size: 1.43rem;
      font-weight: bold;
      font-style: italic;
      color: #E7BD71;
    }
  }
}

.introduce-cont {
  padding: 2rem 8vw;

  .title {

    font-size: 1.07rem;
    font-weight: 400;
    color: #E7BD71;
  }

  p {
    font-size: 0.86rem;
    color: #B5904E;
  }
}
</style>
